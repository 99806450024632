import React from "react";

export const mobileChartSliderSettings = {
  customPaging() {
    return <div className="custom-dots" />;
  },
  dotsClass: "slick-dots custom-slick-dots",
  adaptiveHeight: false,
  dots: false,
  infinite: false,
  fadeIn: false,
  pauseOnHover: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  lazyLoad: false,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

export const mobileSliderSettings = {
  ...mobileChartSliderSettings,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};
